<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>
    <div class="col-12 col-md-12">
      <UserBar :period="period" :OPAnalyst="OPAnalyst" v-on:changePeriod="updatePeriod($event)"></UserBar>
    </div>
    <div class="col-12 col-md-12 row">
      <div class="col-6 col-md-6">
        <div class="col-12 col-md-12 card">
          <div class="card-body text-center py-3">
            <div class="row col-12 cold-sm-12 col-md-12 p-0">
              <h3 class="mb-0 text-center col-12 cold-sm-12 col-md-12 pb-2 bb-1">Importação Marítima</h3>
              <div v-for="item in transferIM" :key="item.qtd" class="col-6 col-sm-6 col-md-6 p-0 bb-1 br-1 bl-1">
                <div class="d-flex mb-3">
                  <div class="col-12 cold-sm-12 col-md-12 p-0">
                    <h5 class="box-title mb-0 text-center">{{ item.motivo_transferencia }}</h5>
                  </div>
                </div>
                <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                  <div class="h1 mb-0 text-center p-0">
                    {{ item.qtd }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-6">
        <div class="col-12 col-md-12 card">
          <div class="card-body text-center py-3">
            <div class="row col-12 cold-sm-12 col-md-12 p-0">
              <h3 class="mb-0 text-center col-12 cold-sm-12 col-md-12 pb-2 bb-1">Exportação Marítima</h3>
              <div v-for="item in transferEM" :key="item.qtd" class="col-6 col-sm-6 col-md-6 p-0 bb-1 br-1 bl-1">
                <div class="d-flex mb-3">
                  <div class="col-12 cold-sm-12 col-md-12 p-0">
                    <h5 class="box-title mb-0 text-center">{{ item.motivo_transferencia }}</h5>
                  </div>
                </div>
                <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                  <div class="h1 mb-0 text-center p-0">
                    {{ item.qtd }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-6">
        <div class="col-12 col-md-12 card">
          <div class="card-body text-center py-3">
            <div class="row col-12 cold-sm-12 col-md-12 p-0">
              <h3 class="mb-0 text-center col-12 cold-sm-12 col-md-12 pb-2 bb-1">Exportação Aérea</h3>
              <div v-for="item in transferEA" :key="item.qtd" class="col-6 col-sm-6 col-md-6 p-0 bb-1 br-1 bl-1">
                <div class="d-flex mb-3">
                  <div class="col-12 cold-sm-12 col-md-12 p-0">
                    <h5 class="box-title mb-0 text-center">{{ item.motivo_transferencia }}</h5>
                  </div>
                </div>
                <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                  <div class="h1 mb-0 text-center p-0">
                    {{ item.qtd }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-6">
        <div class="col-12 col-md-12 card">
          <div class="card-body text-center py-3">
            <div class="row col-12 cold-sm-12 col-md-12 p-0">
              <h3 class="mb-0 text-center col-12 cold-sm-12 col-md-12 pb-2 bb-1">Importação Aérea</h3>
              <div v-for="item in transferIA" :key="item.qtd" class="col-6 col-sm-6 col-md-6 p-0 bb-1 br-1 bl-1">
                <div class="d-flex mb-3">
                  <div class="col-12 cold-sm-12 col-md-12 p-0">
                    <h5 class="box-title mb-0 text-center">{{ item.motivo_transferencia }}</h5>
                  </div>
                </div>
                <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                  <div class="h1 mb-0 text-center p-0">
                    {{ item.qtd }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import KpiService from '@/services/KPI/KpiService'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import UserBar from '@/components/Kpi/UserBar.vue'

export default {
  name: 'OperationalAnalysts',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.analyst.operatinalList') + ' - %s'
    }
  },
  data () {
    return {
      isLoading: false,
      fullPage: true,
      OPAnalyst: false,
      period: '',
      transferEM: [],
      transferIM: [],
      transferEA: [],
      transferIA: []
    }
  },
  components: {
    Loading,
    UserBar
  },
  beforeMount () {

  },
  mounted () {
    this.getTransfers()
  },
  methods: {
    getTransfers () {
      this.isLoading = true

      KpiService.getActivityTransferList(this.period).then(res => {
        this.transferEM = res.data.transferEM
        this.transferIM = res.data.transferIM
        this.transferEA = res.data.transferEA
        this.transferIA = res.data.transferIA

        this.isLoading = false
      })
    },
    updatePeriod (periodUpdated) {
      this.period = periodUpdated
    }
  },
  computed: {

  },
  watch: {
    period: {
      handler () {
        this.getTransfers()
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .text-success {
    color: #3c763d;
  }
  .h1 {
    font-size: 2.2rem;
  }
  .bt-1 {
    border-top: 1px solid #cecece;
  }
  .bb-1 {
    border-bottom: 1px solid #cecece;
  }
  .br-1 {
    border-right: 1px solid #cecece;
  }
  .bl-1 {
    border-left: 1px solid #cecece;
  }
  .desc-txt {
    font-size: 12px; line-height: 14px; margin-bottom: 6px;
  }
</style>
